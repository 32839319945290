export const SET_MESSAGES = 'SET_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const PATCH_MESSAGE = 'PATCH_MESSAGE';
export const SET_QUICK_RESPONSES = 'SET_QUICK_RESPONSES';
export const SET_MODE = 'SET_MODE';
export const SET_LIVEAGENT_DATA = 'SET_LIVEAGENT_DATA';
export const SET_AGENT_STATUS = 'SET_AGENT_STATUS';
export const SET_IS_TYPING = 'SET_IS_TYPING';
export const CANCEL_FILEUPLOADS = 'CANCEL_FILEUPLOADS';
export const SET_COMPOSER_FORM = 'SET_COMPOSER_FORM';
export const SET_CAROUSEL_ITEMS = 'SET_CAROUSEL_ITEMS';
export const SET_SELECTED_CAROUSEL_ITEMS = 'SET_SELECTED_CAROUSEL_ITEMS';
export const SET_CAROUSEL_SUBMITTED = 'SET_CAROUSEL_SUBMITTED';
export const DISPLAY_CAROUSEL = 'DISPLAY_CAROUSEL';