import { 
  SET_MESSAGES, 
  PATCH_MESSAGE,
  ADD_MESSAGE,
  SET_QUICK_RESPONSES,
  SET_MODE,
  SET_AGENT_STATUS,
  SET_IS_TYPING,
  CANCEL_FILEUPLOADS,
  SET_COMPOSER_FORM,
  DISPLAY_CAROUSEL,
  SET_CAROUSEL_ITEMS,
  SET_SELECTED_CAROUSEL_ITEMS,
  SET_CAROUSEL_SUBMITTED
} from '../constants/messages';
      
export const setMessages = (messages) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGES,
      messages: messages
    });
  };
};

export const patchMessages = (payload) => {	
  return (dispatch) => {	
    dispatch({	
      type: PATCH_MESSAGE,	
      message: payload	
    });	
  };	
};

export const addMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      message: message
    });
  };  
};

export const setQuickResponses = (quickResponses) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_RESPONSES,
      quickResponses: quickResponses
    });
  };
};

export const setComposerForm = (composerForm) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMPOSER_FORM,
      composerForm: composerForm
    });
  };
};

export const setMode = (mode) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODE,
      mode: mode.toLowerCase()
    });
  };
};

export const setAgentStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_STATUS,
      agentStatus: status
    });
  };
};

export const setIsTyping = (isTyping) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_TYPING,
      typing: isTyping
    });
  };
};

export const cancelFileUploads = () => {	
  return (dispatch) => {	
    dispatch({	
      type: CANCEL_FILEUPLOADS	
    });	
  };	
};

export const setCarouselItems = (items) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAROUSEL_ITEMS,
      carouselItems: items
    });
  };
};

export const setSelectedCarouselItems = (carouselItems) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CAROUSEL_ITEMS,
      selectedCarouselItems: carouselItems
    });
  };
};

export const setCarouselSubmitted = (isSubmitted) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAROUSEL_SUBMITTED,
      carouselSubmitted: isSubmitted
    });
  };
};

export const setCarouselDisplayed = (displayed) => {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_CAROUSEL,
      displayCarousel: displayed
    });
  };
};