import React from 'react';
import Skeleton from '../Skeleton/Skeleton';

const MuiPlaceholder = ({ element, isMuiFontLoaded, width, height, backgroundColor, onClick }) => {
    if (isMuiFontLoaded) {
        return React.cloneElement(element, { onClick });
    }

    return (
        <Skeleton 
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            onClick={onClick}
        />
    );
};

export default MuiPlaceholder;
