import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classes from './QuickResponseSliderButton.module.scss';
import Message from '../../../models/Message';
import { patchMessages, addMessage, setCarouselSubmitted, setCarouselDisplayed } from '../../../store/actions/messages';
import { hideComposerButtons } from '../../../store/actions/general';

const QuickResponseSliderButton = (props) => {
  const [hovered, setHovered] = useState(false);
  const { carouselItems, messages  } = useSelector(state => state.messages);
  const dispatch = useDispatch();
  const index = messages.findLastIndex(item => item.type === "carousel");
  const selectedCarouselItems = messages[index]?.parameters.selectedCarouselItems || [];

  const setCoverageType = () => {
    dispatch(setCarouselSubmitted(true));
    dispatch(setCarouselDisplayed(false));
    dispatch(addMessage(new Message({
      text: "Okay",
      mode: 'user'
    })));
    dispatch(hideComposerButtons(true));
    const messagesList = {
      index : index,
      key: "parameters",
      value: { ...messages[index].parameters, carouselSubmitted: true }
    }
    dispatch(patchMessages(messagesList));
    props.socket.emit('send', {
      name: "set-coverage-type",
      parameters: {
        "selectedProducts": selectedCarouselItems
      }
    });
    dispatch(setCarouselSubmitted(false));
  }
  return (
    <div className={classes.QuickResponse}>
      <div>
        <button
          className={classes.QuickResponseButton}
          onClick={setCoverageType}
          style={hovered ? window.sutherland_variables.ui.quick_replies.hover : window.sutherland_variables.ui.quick_replies.normal}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          Okay ({selectedCarouselItems.length} of {carouselItems.length} products selected)
        </button>
      </div>
    </div>
  );
};

QuickResponseSliderButton.propTypes = {
  activateResponse: PropTypes.func,
  quickResponse: PropTypes.object
};

export default QuickResponseSliderButton;
