import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import PrechatForm from '../PrechatForm/PrechatForm';
import MainContainer from '../MainContainer/MainContainer';
import ChatLauncher from '../../components/ChatLauncher/ChatLauncher';
import { setMuiFontLoaded, setAvatarUrl } from '../../store/actions/general';

function RootContainer() {
  // eslint-disable-next-line
  const [auto_open, set_auto_open] = useState(window.sutherland_variables.ui.auto_open || false);
  const { prechatEnabled, prechatFormOpened, widgetIsOpen } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const Container = () => {
    const { ui } = window.sutherland_variables;
    dispatch(setAvatarUrl(ui.avatar_url));
    
    if (prechatEnabled && prechatFormOpened) {
      return (
        <PrechatForm />
      )
    }

    return (
      <MainContainer />
    )
  }

  useEffect(() => {
    const font = new FontFaceObserver('Material Icons');

    dispatch(setMuiFontLoaded(false))
    font.load(null, 10000)
      .then(() => {
        dispatch(setMuiFontLoaded(true))
      })
      .catch((error) => {
        dispatch(setMuiFontLoaded(false))
      });
  }, [dispatch]);

  return useMemo(() => {
    return (
      <div>
        {widgetIsOpen || auto_open ? (
          <Container />
        ) : null}
        {!auto_open ? (
          <ChatLauncher />
        ) : null}
      </div>
    );
  }, [widgetIsOpen, auto_open])
}

export default RootContainer;
