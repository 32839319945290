import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import CarouselComponent from "./CarouselComponent";
import carouselClassesModule from "./Carousel.module.scss";
import MuiPlaceholder from '../../../../components/controls/MuiPlaceholder/MuiPlaceholder';

function Carousel(props) {
  const { muiFontLoaded } = useSelector(state => state.general);
  const { carouselItems } = useSelector(state => state.messages);  
  const carouselWidthRef = useRef(null);
  const [itemsToDisplay, setItemsToDisplay] = useState(window.sutherland_variables.ui.carousel_items);
  const [itemCount, setItemCount] = useState(carouselItems.length);
  const [transition, setTransition] = useState(false); 
  const [currItem, setCurrItem] = useState(Math.round((itemCount * 3) / 3)); 
  const [carouselWidth, setCarouselWidth] = useState(100); 
  const [carouselItemWidth, setCarouseItemWidth] = useState(0); 
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  const resizeCarousel = () => {
      const w = carouselWidthRef.current.getBoundingClientRect().width;
      const { size, position } = window.sutherland_variables.ui;
      const breakpoints = [
        { max: Infinity, min: 1024, items: window.sutherland_variables.ui.carousel_items },
        { max: 1024, min: 908, items: 4 },
        { max: 908, min: 640, items: 3 },
        { max: 640, min: 480, items: 2 },
        { max: 480, min: 0, items: 1 },
      ];
      
      if (size !== "100%" && ["center", "parent"].includes(position)) {
        setContainerWidth(props.box.current.clientWidth)
      }
      breakpoints.some(({ max, min, items }) => {
        if (containerWidth > min && containerWidth < max) {
          setItemsToDisplay(items);
          setItemCount(items);
          return true;
        } 
        return false;
      });

      const realCarouselWidth = (100 / itemCount) * itemsToDisplay;
      setCarouselWidth(realCarouselWidth);

      setTimeout(() => {
        const gaps = [40, 20, 14, 10, 8]
        const gapIndex = itemsToDisplay > 4 ? 4 : itemsToDisplay - 1;
        let itemWidth = (w / itemCount) - gaps[gapIndex];
        setCarouseItemWidth(itemWidth)
        setTimeout(() => {
          setTransition(true)
        }, 50)
      }, 10)
  }

  useEffect(() => {
    if (carouselWidthRef.current) {
      resizeCarousel()
    }
  });

  useEffect(() => {
    const handleResize = () => {
      resizeCarousel()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  
  const nextSlide = () => {
    if (currItem === 12) {
      setTransition(false)
      setTimeout(() => {  
        setCurrItem(2)
        setTimeout(() => {
          setTransition(true)
          setCurrItem(3)
        }, 10)
      }, 10)
    } else {
      setCurrItem(currItem + 1)
    }
  };

  const prevSlide = () => {
    if (currItem === 0) {
      setTransition(false)
      setTimeout(() => {  
        setCurrItem(5)
        setTimeout(() => {
          setTransition(true)
          setCurrItem(4)
        }, 10)
      }, 10)
    } else {
      setCurrItem(currItem - 1)
    }
  };

  return (
    <>
      <button
        onClick={prevSlide}
        className={[carouselClassesModule.CarouselButton, carouselClassesModule.PrevButton].join(" ")}
      >
        <MuiPlaceholder
          isMuiFontLoaded={muiFontLoaded}
          element={<div>arrow_back_ios_icon</div>}
          width={30}
          height={30}
          backgroundColor={'#eeeeee'} />
      </button>
      <div ref={carouselWidthRef} className={carouselClassesModule.CarouselContainerAdjuster}></div>
      <div style={{
        width: containerWidth > 480 ? `calc(${carouselWidth}% - ${itemsToDisplay * 10}px)` : '100%'}}
        className={carouselClassesModule.CarouselContainer}>
        <CarouselComponent openInPopup="false" itemWidth={carouselItemWidth} currentIndex={currItem} hasTransition={transition} message={props.message} index={props.index}/>
      </div>
      <button
        onClick={nextSlide}
        style={{
          left: containerWidth > 480 ? `${(carouselItemWidth * itemsToDisplay)+70}px` : `${carouselItemWidth+70}px`
        }}
        className={[carouselClassesModule.CarouselButton].join(" ")}
      >
        <MuiPlaceholder
          isMuiFontLoaded={muiFontLoaded}
          element={<div>arrow_forward_ios_icon</div>}
          width={30}
          height={30}
          backgroundColor={'#eeeeee'} />
      </button>
    </>
  );
}

Carousel.propTypes = {
  message: PropTypes.object,
};

export default Carousel;
