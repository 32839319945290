import React from 'react';
import { createRoot } from 'react-dom/client';
import * as retargetEvents from 'react-shadow-dom-retarget-events';
import './index.scss';
import App from './App';

const styleNodes = [];
let shadowRoot = null;
const headNode = document.head;
const config = { attributes: true, childList: true, subtree: true };

const cssPanelStyle = document.createElement('style');
cssPanelStyle.setAttribute('target', 'parent');
cssPanelStyle.innerHTML = `
  #bot-panel {
    position: relative;
    height: 50vh;
  }
  @media only screen and (max-width: 999px) {
    #bot-panel {
      position: absolute !important;
      width: 90%;
      height: 70vh !important;
      margin-left: auto;
      margin-right: auto;
      top: 20px;
      left: 0;
      right: 0;
      bottom: auto;
      text-align: center;
      z-index: 1;
    }
  }
`;
document.head.appendChild(cssPanelStyle);

const observerCallback = function(mutationList, observer) {
  for(const mutation of mutationList) {
    if (mutation.type === 'childList') {
      for(const nodeElem of mutation.addedNodes) {
        if (nodeElem instanceof HTMLStyleElement) {
          shadowRoot.appendChild(nodeElem);
        }
      }
    }
  }
};

if (process.env.NODE_ENV !== 'production') {
  for(let elem of headNode.children) {
    if (elem instanceof HTMLStyleElement && elem.getAttribute('target') !== 'parent') {
      elem.setAttribute('loaded', true);
      styleNodes.push(elem);
    }
  }
}

const observer = new MutationObserver(observerCallback);

class SutherlandMessenger extends HTMLElement {
  static get observedAttributes() {
    return ['bot-id', 'enable-debug', 'engine-base-host'];
  }

  get botId() {
    return this.parseAttributes('bot-id', '', false);
  }

  get enableDebug() {
    return this.parseAttributes('enable-debug', false, true);
  }
  
  get engineBaseUrl() {
    const hostSubdomain = this.parseAttributes('engine-base-host', '', false);
    let baseUrl = hostSubdomain !== '' ? `https://${hostSubdomain}.sutherland.ai` : 'https://conversation-dev.sutherland.ai';

    if (hostSubdomain.includes('localhost') || hostSubdomain.includes('127.0.0.1')) {
      baseUrl = `http://${hostSubdomain}`;
    }

    return baseUrl;
  }

  parseAttributes(attrName, defaultValue, asObject) {
    try {
      const attributes = this.getAttribute(attrName);
      const parseAsObject = typeof asObject === 'undefined' ? true : asObject;
      if (attributes) {
        if (parseAsObject) {
          return JSON.parse(attributes);
        }
        return attributes;
      }
    } catch(e) {
      console.log(`Error loading ${attrName} attribute.`, e);
    }
    return defaultValue;
  }

  async fetchCssUrls(urls) {
    const results = [];
    
    for (const url of urls) {
      if (url) {
        const response = await fetch(url);
        const data = await response.text();
        results.push(data);
      }
    }
    
    return results;
  }

  mountChatMessenger(config) {
    if (config) {
      if (!window.sutherland_variables) {
        window.sutherland_variables = {};
      }

      window.sutherland_variables["botId"] = this.botId;
      window.sutherland_variables["enabled"] = config['enabled'];
      window.sutherland_variables["engineBaseUrl"] = this.engineBaseUrl;
      window.sutherland_variables["ui"] = config['ui'];
      window.sutherland_variables["prechatEnabled"] = config['prechat-enabled'];
      window.sutherland_variables["cssStylesUrl"] = config['css-styles-url'];
      window.sutherland_variables["i18n"] = config['i18n'];
      window.sutherland_variables["language"] = config['language'];

      if (!config['enabled']) {
        return false;
      }

      const mountPoint = document.createElement('div');
      const cdnHost = process.env.NODE_ENV === 'production' ? 'cdn.sutherland.ai' : 'cdn-staging.sutherland.ai';
      const fontList = [
        'https://fonts.googleapis.com/icon?family=Material+Icons&elem=sutherland-messenger',
        'https://fonts.googleapis.com/css?family=Noto Sans:400,700',
        'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,400&display=swap',
        'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,300;1,400&display=swap',
        `https://${cdnHost}/static/fonts/proximanova/font.css`
      ];
      const externalCssUrls = (process.env.NODE_ENV === 'production' && window.sutherland_variables.cssStylesUrl !== '') ?
        [ window.sutherland_variables.cssStylesUrl ] : [];
      
      shadowRoot = this.attachShadow({ mode: 'open' });

      fontList.forEach((font) => {
        const fontHeaderElem = document.createElement('link');    
        fontHeaderElem.href = font;
        fontHeaderElem.rel = 'stylesheet';
        document.head.appendChild(fontHeaderElem);
      });

      this.fetchCssUrls(externalCssUrls)
        .then(cssContentArr => {
          for (const cssContent of cssContentArr) {
            const cssStyle = document.createElement('style');
            cssStyle.setAttribute('nonce', 'YmhhcmF0');
            cssStyle.innerHTML = cssContent;
            shadowRoot.appendChild(cssStyle);
          }
          const root = createRoot(mountPoint);
          styleNodes.forEach((node) => {
            shadowRoot.appendChild(node);
          });
          shadowRoot.appendChild(mountPoint);
          root.render(<App />);
          retargetEvents(shadowRoot);
        })
        .catch(error => {
          // handle the error
          console.log('error', error)
        });

        const shadowHost = document.querySelector('sutherland-messenger');
        const shadowRootVal = shadowHost.shadowRoot;

        // Universal selector for interactive elements
        const selector = 'button, a, input, textarea, select, [role="button"], [tabindex]:not([tabindex="-1"])';

        function patchElement(el) {
          if (!el || el.dataset.touchPatched) return;

          el.dataset.touchPatched = 'true';
          el.style.touchAction = 'manipulation';
          el.style.cursor = 'pointer';

          el.addEventListener('touchend', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const tag = el.tagName.toLowerCase();

            // Trigger click only for clickable elements
            if (['button', 'a'].includes(tag) || el.getAttribute('role') === 'button') {
              el.click?.();
            }

            // Focus for inputs and textareas
            if (['input', 'textarea', 'select'].includes(tag)) {
              el.focus?.();
            }
          }, { passive: false });
        }

        // Patch existing elements
        shadowRoot.querySelectorAll(selector).forEach(patchElement);

        // Watch for dynamically added elements
        new MutationObserver((mutations) => {
          for (const m of mutations) {
            for (const node of m.addedNodes) {
              if (node.nodeType === 1) {
                if (node.matches?.(selector)) patchElement(node);
                node.querySelectorAll?.(selector).forEach(patchElement);
              }
            }
          }
        }).observe(shadowRootVal, { childList: true, subtree: true });
    }
  }

  connectedCallback() {
    observer.observe(headNode, config);
    const urlParams = new URLSearchParams(window.location.search);
    let cacheDebug = JSON.parse(urlParams.get('enable_debug'));
    cacheDebug = !cacheDebug && !this.enableDebug ? false : true;
    const rootKey = `persist:${window.location.hostname}_${this.botId}_root`
    const configKey = `${window.location.hostname}_${this.botId}_chatconfig`
    const chatConfig = !cacheDebug && !this.enableDebug ? sessionStorage.getItem(configKey) : false;
    let refreshConfig = false;

    if (!chatConfig) {
      refreshConfig = true;
    } else {
      const config = JSON.parse(chatConfig)
      const currentTime = new Date().getTime();
      if (currentTime - config['timeout'].timestamp >= config['timeout'].refreshAfter) {
        refreshConfig = true;
      }
    }

    if (refreshConfig) {
      const configHeaders = {
        'Content-Type': 'application/json',
        'bot-config': this.botId
      }

      if (cacheDebug) {
        configHeaders['bypass-cache'] = "true";
      }
      
      fetch(`${this.engineBaseUrl}/api/messenger/config`, {
        method: 'GET',
        headers: configHeaders
      })
        .then(response => response.json())
        .then(data => {
          data['config']['timeout'] = {
            timestamp: new Date().getTime(),
            refreshAfter: 15 * 60 * 1000 // 15 minutes
          }
          sessionStorage.setItem(configKey, JSON.stringify(data['config']))
          this.mountChatMessenger(data['config'])
        });
    } else {
      this.mountChatMessenger(JSON.parse(chatConfig))
    }
    window.addEventListener('unload', function(event) {
      console.log('reset session storage');
      sessionStorage.removeItem(rootKey);
      sessionStorage.removeItem(configKey);
    });
  }
}

window.customElements.get("sutherland-messenger") ||
window.customElements.define("sutherland-messenger", SutherlandMessenger);
