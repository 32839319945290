import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './MessageComposer.module.scss';
import QuickResponseContainer from '../../containers/QuickResponseContainer/QuickResponseContainer';
import QuickResponseSliderButton from '../../containers/QuickResponseContainer/QuickResponseSliderButton/QuickResponseSliderButton';
import Text from '../controls/Text/Text';
import Message from '../../models/Message';
import { addMessage, setQuickResponses, setComposerForm } from '../../store/actions/messages';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}


const MessageComposer = ({socket}) => {
  const { t } = useTranslation();
  const { sessionId, composerButtonsHidden } = useSelector(state => state.general);
  const { mode, composerForm, carouselItems, displayCarousel, carouselSubmitted, messages } = useSelector(state => state.messages);
  const [newMessage, setNewMessage] = useState('');
  const debouncedInputValue = useDebounce(newMessage, 500);
  const [isTypingState, setisTypingState] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [composerRef, setComposerRef] = useState(null);
  const index = messages.findLastIndex(item => item.type === "carousel");
  const selectedCarouselItems = messages[index]?.parameters.selectedCarouselItems || [];

  const dispatch = useDispatch()
  const send = (textareaRef = null) => {
    if (newMessage.trim() !== '') {
      socket.emit("send", {
        message: newMessage,
        session_id: sessionId,
        mode: mode,
        messenger_id: window.sutherland_variables.botId
      });
      if(textareaRef && textareaRef.current) { 
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        composerRef.current.style.height = 'inherit';
        setLimitReached(false);
      }
      const message = {
        "text": newMessage,
        "mode": "user"
      }
      dispatch(addMessage(new Message(message)));
      dispatch(setQuickResponses([]));
      setNewMessage('')
      setisTypingState(false);
      dispatch(setComposerForm(message.options));
    }
  };

  const handleTypingStart = (message, textareaRef = null) => {
    setNewMessage(message)
    if(textareaRef) { 
      textareaRef.current.style.height = 'inherit';
      if(message.length === 250) {
        setLimitReached(true);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;
      } else if (message.length === 0) {
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        setLimitReached(false);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      setComposerRef(textareaRef);
    }
    if (!isTypingState) {
      setisTypingState(true);
      socket.emit('is-typing', {})
    }
  };

  const stopTyping = useCallback(() => {
    socket.emit('stop-typing', {})
    setisTypingState(false);
  }, [socket]);

  const qrButtonsContainer = (classes, socket) => {
    if (composerButtonsHidden) {
      return <div />
    }
    if (!carouselSubmitted && displayCarousel) {
      if (selectedCarouselItems.length > 0) {
        return carouselItems.length > 0 ?
          <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}>
            <QuickResponseSliderButton 
              socket={socket} 
            />
          </div>
        : <div /> 
      } else {
        return <div />
      }
    } 
    return <QuickResponseContainer socket={socket}/>
  }

  useEffect(() => {
    if (newMessage === debouncedInputValue && isTypingState && newMessage !== '') {
      stopTyping()
    }
  }, [debouncedInputValue, newMessage, isTypingState, stopTyping]);
  
  return (
    <div className={classes.MessageComposer} style={window.sutherland_variables.ui.message_composer.container}>
      
      {composerForm ? 
         <>
            <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}
            style={{padding: "0.65em 0.42em 0 0.42em"}}
            >
            <Text
              value={newMessage}
              onChange={handleTypingStart}
              placeholder={composerForm[0]['placeholder-text']}
              onEnter={send}
              name='message'
              maxLength={250}
            />
            </div>
            {limitReached && <p className={classes.error}>{t('composer.limitReached','250-character limit reached')}</p> }
            <button className={classes.Send} onClick={send}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.71285 16.6211L17.4765 7.53953C17.6229 7.39982 17.33 7.12038 17.1835 7.2601L7.95505 15.0842L4.43945 13.6871C3.85352 13.4775 3.85352 12.709 4.43945 12.3598L19.3075 4.11647C19.9667 3.76718 20.6259 4.25619 20.4794 4.88492L18.2089 18.158C18.0624 18.647 17.5497 18.9264 17.1103 18.7169L13.1552 17.1101L11.0312 19.4853C10.5917 20.0442 9.71285 19.7647 9.71285 19.0662V16.6211Z" fill="#808080"
                style={{fill: window.sutherland_variables.ui.message_composer.icon_colors}}
                />
              </svg>
            </button>
         </>
        : qrButtonsContainer(classes, socket)
      }
    </div>
  );
};

export default MessageComposer;