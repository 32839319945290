import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import classes from './App.module.scss';
import RootContainer from './containers/RootContainer/RootContainer';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import messages from './store/reducers/messages';
import general from './store/reducers/general';
import prechat from './store/reducers/prechat';
import authentication from './store/reducers/authentication';
import { setSessionId } from './store/actions/general';
import { setMessages, setMode } from './store/actions/messages';
import { setResetChat, setDrawerIsOpen, setWidgetIsOpen, setAlertIsOpen } from './store/actions/general';

function App() {
  const component = document.getElementsByTagName("sutherland-messenger")[0];
  const botId = component.getAttribute('bot-id');
  const configKey = `${window.location.hostname}_${botId}_root`

  const persistConfig = {
    key: configKey,
    storage: storageSession
  };

  const rootReducer = combineReducers({
    messages: messages,
    general: general,
    prechat: prechat,
    authentication: authentication,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

  store.subscribe(() => {
    // Check persist timeout
    const state = store.getState()
    const currentDate = new Date(); // Get the current date and time
    const sessionStart = new Date(state.general.sessionStartTime);
    const timeDifference = currentDate.getTime() - sessionStart.getTime(); // Calculate the difference in milliseconds
    const secondsElapsed = timeDifference / 1000; // Convert milliseconds to seconds
    const sessionExpirySeconds = 60 * 60 * 3; // 3 hours

    if (secondsElapsed > sessionExpirySeconds) {
      console.log('\u25B6 Chat session expired, restarting...');
      store.dispatch(setResetChat(true))
      store.dispatch(setWidgetIsOpen(false));
      store.dispatch(setDrawerIsOpen(false))
      store.dispatch(setAlertIsOpen(true));
      store.dispatch(setMode("bot"))
      store.dispatch(setSessionId(''))
      store.dispatch(setMessages([]))
    }
  });

  const persistor = persistStore(store);

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: window.sutherland_variables.language,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      resources: window.sutherland_variables.i18n
    });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className={classes.App}>
          <RootContainer />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
