import React, { useState } from 'react';
import classes from './FeedbackModal.module.scss';
import Modal from '../Modal/Modal';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import { useSelector } from 'react-redux';

const FeedbackModal = ({ isOpen, closeHandler }) => {
  const { sessionId, muiFontLoaded } = useSelector((state) => state.general);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleSendFeedback = (rateVal) => {
    setFeedbackSubmitted(true)
    setHoverIndex(rateVal)
   
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        session_id: sessionId,
        rating: rateVal
      }),
      headers: {
        'Bot-Config': window.sutherland_variables.botId,
        'Content-Type': 'application/json',
      },
    };
    
    fetch(
        `${window.sutherland_variables.engineBaseUrl}/api/messenger/rating`,
        requestOptions
      ).then(() => {
        // do nothing
      }
    );
    setTimeout(()=> {
      closeHandler();
    },1500)
  }

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'300px'}>
      <div className={classes.FeedbackModalContainer}>
        <h2>How was it?</h2>
        <div className={classes.FeedbackModalContent}>
          <div className={classes.Ratings}>
            <div className={classes.Label}>Rate your overall experience.</div>
            <div className={classes.Icon}>
              {[1, 2, 3, 4, 5].map((value) => (
                <MuiPlaceholder
                  key={value}
                  isMuiFontLoaded={muiFontLoaded}
                  element={
                    <button
                      style={{
                        color:
                          value <= hoverIndex
                            ? 'rgb(255, 215, 0)'
                            : 'rgb(204, 204, 204)',
                      }}
                      onMouseEnter={() => !feedbackSubmitted && setHoverIndex(value)}
                      onMouseLeave={() => !feedbackSubmitted && setHoverIndex(-1)}
                    >
                      star
                    </button>
                  }
                  width={80}
                  height={80}
                  onClick={()=>handleSendFeedback(value)}
                  disabled = {feedbackSubmitted}
                  />
              ))}
            </div>
          </div>
          <div className={classes.Buttons}>
            <button className={classes.ButtonSkip} onClick={closeHandler}>Skip</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
