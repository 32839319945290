import React from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';

const Header = ({socket}) => {


  return (
    <div className={classes.Header} style={window.sutherland_variables.ui.header_styles.background}>
      <div className={classes.HeaderTitle} style={window.sutherland_variables.ui.header_styles.title}>
        {window.sutherland_variables.ui.header_image_url ? (
          <div className={classes.Image}>
            <div>
            <img
              src={window.sutherland_variables.ui.header_image_url}
              alt='Logo'
              style={{
                width: window.sutherland_variables.ui.header_image_size,
              }}
            />
            </div>
            <div>
              <Trans i18nKey="header.title">
                {window.sutherland_variables.ui.header_title}
              </Trans><br/>
              <Trans i18nKey="header.subTitle">
                <span className={classes.SubTitle} style={window.sutherland_variables.ui.header_styles.sub_title}>{window.sutherland_variables.ui.header_sub_title}</span>
              </Trans>
            </div>
          </div>
        ) : (
          <div>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
