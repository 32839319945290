import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import carouselClassesModule from "./Carousel.module.scss";
import MuiPlaceholder from '../../../../components/controls/MuiPlaceholder/MuiPlaceholder';
import { patchMessages } from '../../../../store/actions/messages';

function CarouselComponent(props) {
  const { muiFontLoaded } = useSelector(state => state.general);
  const { carouselItems } = useSelector(state => state.messages);
  const infiniteSlides = [...carouselItems, ...carouselItems, ...carouselItems];
  const [hovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const selectedCarouselItems = props.message.parameters?.selectedCarouselItems || []
  const selectHandler = (code) => {
    if (!props.message.parameters.carouselSubmitted) {
      const selected = selectedCarouselItems.some(obj => obj.code === code)
      ? selectedCarouselItems.filter((item) => item.code !== code) // Deselect if already selected
      : [...selectedCarouselItems, {"code": code}] // Select the slide
      const messagesList = {
        index : props.index,
        key: "parameters",
        value: { selectedCarouselItems: selected }
      }
      dispatch(patchMessages(messagesList));
    }
  };

  const slideStyle = {
    width: `${props.itemWidth-10}px`,
  };  
  return (
    <div className={`${carouselClassesModule.containerStyle} ${carouselClassesModule.imageBorder}`}>
      <div 
          style={{transform: `translateX(${-1 * props.currentIndex * props.itemWidth}px)`}} 
          className={[
            carouselClassesModule.CarouselTrack, 
            props.hasTransition ? carouselClassesModule.CarouselTransition : ""].join(" ")
      }>
        {infiniteSlides.map((data, index) => (
          <div key={index} 
            className={`${carouselClassesModule.CarouselItem} ${
              selectedCarouselItems.some(obj => data.code === obj.code) 
                ? carouselClassesModule.CarouselItemSelected 
                : ''}`} 
            style={slideStyle}>
            <img
              src={data.url}
              alt={`Slide ${index}`}
              style={{ margin: "0 auto"}}
            />
            <div className={carouselClassesModule.carouselBot}>
              <h1>{data.title}</h1>
              <p>{data.description}</p>
              <button
                className={carouselClassesModule.SelectButton}
                onClick={() => selectHandler(data.code)}
                style={{
                  ...hovered
                    ? window.sutherland_variables.ui.quick_replies.hover
                    : window.sutherland_variables.ui.quick_replies.normal,
                  width: '100%',
                  backgroundColor: props.message.parameters.carouselSubmitted ? "#DBDBDB" : window.sutherland_variables.ui.quick_replies.normal.backgroundColor,
                  cursor: props.message.parameters.carouselSubmitted ? "default" : "pointer"
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {selectedCarouselItems.some(obj => data.code === obj.code) ? (
                  <>
                  <MuiPlaceholder
                    isMuiFontLoaded={muiFontLoaded}
                    element={<div>check_icon</div>}
                    width={30}
                    height={30}
                    backgroundColor={"#eeeeee"}
                  />
                  <span>Selected</span>
                  </>
                ) : (
                  <span>Select</span>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

CarouselComponent.propTypes = {
  message: PropTypes.object,
};

export default CarouselComponent;
