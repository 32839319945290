import React, { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import { addMinutes, format } from "date-fns";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import classes from './MainContainer.module.scss';
import Header from '../../components/Header/Header';
import Alert from '../../components/Alert/Alert';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import MessageComposer from '../../components/MessageComposer/MessageComposer';
import MessageList from '../MessageList/MessageList';
import Typing from '../Typing/Typing';
//import QuickResponseContainer from '../QuickResponseContainer/QuickResponseContainer';
import Message from '../../models/Message';
import QuickResponse from '../../models/QuickResponse';
import { setSessionId, setAgentName, setResetChat, setEndChat, setFingerprintId, hideComposerButtons } from '../../store/actions/general';
import { addMessage, cancelFileUploads, setQuickResponses, setMode, setIsTyping, setComposerForm, setCarouselItems, setCarouselDisplayed } from '../../store/actions/messages';
import { useDispatch, useSelector } from 'react-redux';

const MainContainer = () => {
  const { prechatEnabled, widgetIsOpen, sessionId, resetChat, endChat, fingerprintId } = useSelector(state => state.general);
  const { messages, typing } = useSelector(state => state.messages);
  // eslint-disable-next-line
  const [auto_open, set_auto_open] = useState(window.sutherland_variables.ui.auto_open || false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const socketRef = useRef(null);
  const boxRef = useRef(null);
  const dispatch = useDispatch();
  const { orientation, size, position, position_width, position_height } = window.sutherland_variables.ui;

  const handleCloseModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      setTimeout(() => {
        const message_end_chat = {
          type: 'status', 
          event: 'ChatEnd',
          text: 'The conversation has ended', 
          mode: 'bot'
        }
        dispatch(addMessage(new Message(message_end_chat)));
      }, 500)
    }, 500) 
  };

  const getDynamicStyle = () => {
    if(size === "100%") {
      return {
        width:'100%',
        maxWidth: '100%',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        height: '100vh'
      }
    } else if (position === "center") {
      return {
        width: position_width,
        maxWidth: position_width,
        height: position_height,
        transform: "translate(-50%, -50%)",
        top: "50%",
        bottom: "auto",
        left: "50%",
        right: "auto"
      }
    } else if (position === "parent") {
      return {
        width: position_width,
        maxWidth: position_width,
        height: position_height,
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "0"
      }
    } else {
      const positionStyles = {
        top: orientation.vertical === 'top' ? '2em' : 'auto',
        bottom: orientation.vertical === 'bottom' ? '2em' : 'auto',
        left: orientation.horizontal === 'left' ? '1em' : 'auto',
        right: orientation.horizontal === 'right' ? '1em' : 'auto',
      };
    
      return {
        ...positionStyles,
        width: size,
        maxWidth: size
      };
    }
  };

  useEffect(() => {
    window.sutherland_variables.resetChat = resetChat;
    window.sutherland_variables.fingerprintId = fingerprintId
    if (!socketRef.current) {
      socketRef.current = io(window.sutherland_variables.engineBaseUrl, {
        path: '/ws/chat/',
        transports: ['websocket', 'polling'],
        upgrade: true,
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 2000,
        randomizationFactor: 0.5,
        auth: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !sessionId ? '': sessionId
        }
      });

      const socket = socketRef.current;

      socket.on('connected', async (data) => {
        console.log('\u25B6 connected');
        if (window.sutherland_variables.resetChat || window.sutherland_variables.resetChat === null) {
          console.log('\u25B6 initialize session');
          dispatch(setSessionId(data.session_id))
          if (!window.sutherland_variables.fingerprintId) {
            const fp = await FingerprintJS.load();
            const { visitorId } = await fp.get();
            window.sutherland_variables.fingerprintId = visitorId
            dispatch(setFingerprintId(visitorId));
          }
          // Auto send message to start convo
          
          const date_string = window?.sutherland_variables?.dateTime || "";
          const dt = date_string !== '' ? new Date(date_string) : new Date();
          const dt_end = addMinutes(dt, 30);

          let calendar_date = "";
          let gcal_date_start = "";
          let gcal_date_end = "";
          if (dt !== '') {
            calendar_date = format(dt, "LLL do 'at' hh:mmaaa")
            gcal_date_start = `${format(dt, "yyyyMMdd'T'HHmmss")}Z`
            gcal_date_end =  `${format(dt_end, "yyyyMMdd'T'HHmmss")}Z`
          }
          socket.emit("send", {
            name: "welcome-event",
            parameters:
              {
                FingerprintId: window.sutherland_variables.fingerprintId, 
                UserAgent: window.sutherland_variables.user_agent,
                Browser: window.sutherland_variables.browser,
                DeviceCategory: window.sutherland_variables.device,
                ScreenResolution: `${window.screen.width}x${window.screen.height}`,
                VisitorIP: window.sutherland_variables.ip_address,
                url: document.URL,
                title: document.title,
                firstName: window?.sutherland_variables?.fname || "",
                lastName: window?.sutherland_variables?.lname || "",
                CID: window?.sutherland_variables?.CID || "",
                phone_number: window?.sutherland_variables?.phone || "",
                zipcode: window?.sutherland_variables?.zipcode || "",
                email: window?.sutherland_variables?.email || "",
                datetime: window?.sutherland_variables?.dateTime || "",
                calendar_date: calendar_date,
                gcal_date_start: gcal_date_start,
                gcal_date_end: gcal_date_end,
                leadType: window?.sutherland_variables?.leadType || "",
              }
          }); 
          
          dispatch(setResetChat(false))
        }
      });

      socket.on('disconnected', (data) => {
        console.log('Client disconnected.')
      });

      socket.on('message-received', (data) => {
        data.mode = data.mode.toLowerCase()
        dispatch(setMode(data.mode))
        dispatch(setIsTyping(false))
        dispatch(hideComposerButtons(false))
        for (const message of data.payload) {
          message.mode = data.mode
          switch(message.type) {
            case 'chips':
              const responsesList = [];
              for (const option of message.options) {
                const newQuickResponse = new QuickResponse(option);
                responsesList.push(newQuickResponse);
              }
              dispatch(setQuickResponses(responsesList));
              break;
            case 'info':
              if (data.mode === "agent") {
                dispatch(setAgentName(data.username));
              }
              message.text = `[${message.title}](${message.link})`;
              dispatch(addMessage(new Message(message)));
              break;
            case 'status':
              switch (message.event) {
                case "ChatEnd":
                  setTimeout(() => {
                    setModalOpen(true)
                  }, 1500);
                  dispatch(setEndChat(true));
                  socket.disconnect();
                  break;
                case "FileRequestCancel":
                  dispatch(addMessage(new Message(message)));
                  dispatch(cancelFileUploads())
                  break;
                default:
                  break;
              }
              break;
            case 'carousel':
              const carouselResponse = {
                  ...message,
                "parameters": {}
              }
              dispatch(setCarouselDisplayed(true));
              dispatch(setCarouselItems(message.options));
              dispatch(addMessage(new Message(carouselResponse)));
            break;
            case 'calendar-event':
              const calendarEvent = {
                  ...message,
                "parameters": message.options
              }
              dispatch(addMessage(new Message(calendarEvent)));
            break;
            case 'composer':
              dispatch(setComposerForm(message.options));
            break;
            default:
              if (message.type !== 'error' && message.type !== 'liveAgentHandoff') {
                if (data.mode === "agent") {
                  dispatch(setAgentName(data.username));
                }
                dispatch(addMessage(new Message(message)));
              }
              break;
          }
        }
      });

      socket.on('agent-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(true))
        setTimeout(() => {
          dispatch(setIsTyping(false))
        }, 1500)
      });

      socket.on('stop-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(false))
      });

      socket.on('file-transfer', (data) => {
        dispatch(setIsTyping(false))
        const message = {
          "type": "file-transfer",
          "text": "",
          "url": data?.url,
        }
        dispatch(addMessage(new Message(message)));
      });

      setIsLoading(false)
    }
  }, [dispatch, sessionId, messages, resetChat, fingerprintId]);

  if (!isLoading) {
    return (
      <div ref={boxRef} className={prechatEnabled || widgetIsOpen || auto_open ? classes.MainContainer : classes.MainContainerGrowIn} 
        style={{...getDynamicStyle()}}>

        <div className={classes.MainContainerOuter}> 
          <Header socket={socketRef.current}/>
          <Alert socket={socketRef.current}/>
          <FeedbackModal isOpen={modalOpen} closeHandler={handleCloseModal} />
          <MessageList  socket={socketRef.current} box={boxRef}/>
          {!endChat &&
            <>
              <Typing show={typing} mode="dot-loader" typingText="" />
              {/* <QuickResponseContainer socket={socketRef.current}/> */}
              <div className={classes.footer}>
                <MessageComposer socket={socketRef.current}/>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
  return <div></div>
};

export default MainContainer;
