import React from 'react';
import PropTypes from 'prop-types';
import classes from './CalendarEvent.module.scss';

function CalendarEvent({ message }) {
  return (
  <>
    <div className={classes.CalendarSec}>
        <div className={classes.CalendarTopSec}>
          <h1 className="calendar-header">Friendly Reminder</h1>
          <h2 className="calendar-date">{message.parameters[0].datetime}</h2>
          <p>
            <span>Confirmation number:</span>
            <span>{message.parameters[0].confirmation_number}</span>
          </p>
        </div>
        <div className={classes.CalendarBotSec} onClick={() => window.open(message.parameters[0].calendar_link, '_blank')}>
          <svg aria-hidden="true" data-prefix="far" data-icon="calendar-plus" className="svg-inline--fa fa-calendar-plus fa-w-14 calendar-icon" role="img" viewBox="0 0 448 512" data-reactid=".0.0.2.0.$0.1:$1732600535040-0.1.0.0.1.0.1:$children/=10">
            <path fill="currentColor" d="M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
          </svg>
          <span>
            Add to Calendar
          </span>
        </div>
    </div>
  </>
  );
}

CalendarEvent.propTypes = {
  message: PropTypes.object,
};

export default CalendarEvent;
